import * as React from "react"

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}
const headingAccentStyles = {
  color: "#663399",
}
const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}
const listStyles = {
  marginBottom: 96,
  paddingLeft: 0,
}
const listItemStyles = {
  fontWeight: "300",
  fontSize: "24px",
  maxWidth: "560px",
}

const linkStyle = {
  color: "#8954A8",
  fontWeight: "bold",
  fontSize: "16px",
  verticalAlign: "5%",
}

const descriptionStyle = {
  color: "#232129",
  fontSize: "14px",
}

// data
const links = [
  {
    text: "Projects",
    url: "https://kalpa.dev/projects",
    description:
      "We've built some cool stuff in the past, check them out.",
    color: "#E95800",
  },
  {
    text: "Hire us",
    url: "https://kalpa.dev/contact",
    description:
      "Need a team of professional software engineers or ui/ux designers for your next project?",
    color: "#1099A8",
  },
  {
    text: "Who we are",
    url: "https://kalpa.dev/about-us",
    description:
      "Read about our philosophy and the things we're most passionate about as a company.",
    color: "#BC027F",
  }
]

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <title>Home Page</title>
      <h1 style={headingStyles}>
        Kalpa Opensource
        <br />
        <span style={headingAccentStyles}>— we're still working on this site! </span>
      </h1>
      <p style={paragraphStyles}>
        This site will the main source of truth for all updates on open source projects developed or being developed by <code style={codeStyles}>Kalpa Services.</code>
        <br/><br/>
        In the meantime, checkout the links below.
      </p>
      <ul style={listStyles}>
        {links.map(link => (
          <li style={{ ...listItemStyles, color: link.color }}>
            <span>
              <a
                style={linkStyle}
                href={`${link.url}?utm_source=starter&utm_medium=start-page&utm_campaign=minimal-starter`}
              >
                {link.text}
              </a>
              <p style={descriptionStyle}>{link.description}</p>
            </span>
          </li>
        ))}
      </ul>
    </main>
  )
}

export default IndexPage
